import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    orderId: String,
    venueId: String,
  };

  static targets = [
    "price",
    "reservationType",
    "startTime",
    "finishTime",
  ];

  connect() {
    this.timeChanged()
  }

  recalculate = () => {
    const defaultRate = parseFloat(
      this.reservationTypeTarget.options[this.reservationTypeTarget.selectedIndex]?.getAttribute(
        "data-price"
      )
    );
    this.priceTarget.value = defaultRate;
  };

  timeChanged = () => {
    console.log(this.orderIdValue);
    console.log(this.startTimeTarget.value);
    console.log(this.finishTimeTarget.value);

    // conflicting_reservations_path
    get(`/venue_reservations/conflicting?order_id=${this.orderIdValue}&start_time=${this.startTimeTarget.value}&finish_time=${this.finishTimeTarget.value}&venue_id=${this.venueIdValue}`, {
      responseKind: 'turbo-stream'
    })
  }
}
