import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="contact-select"
export default class extends Controller {
  change(event) {
    const contactId = event.target.value

    get(`/${contactId}/contact_selected`, {
      responseKind: 'turbo-stream'
    })
  }
}