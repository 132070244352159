import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = [
    "position",
    "quantity",
    "rate",
    "startDate",
    "startTime",
    "finishDate",
    "finishTime",
    "hoursWorked",
    "hoursWorkedError",
  ];

  connect() {
    this.recalculate();
  }

  recalculateRate() {
    const defaultRate = parseFloat(
      this.positionTarget.options[this.positionTarget.selectedIndex]?.getAttribute(
        "data-rate"
      )
    );
    this.rateTarget.value = defaultRate;

    this.recalculate();
  }

  recalculate() {
    const defaultRate = parseFloat(
      this.positionTarget.options[this.positionTarget.selectedIndex]?.getAttribute(
        "data-rate"
      )
    );
    this.rateTarget.value = defaultRate;

    this.recalculate();
  }

  recalculate() {
    const defaultRate = parseFloat(
      this.positionTarget.options[this.positionTarget.selectedIndex]?.getAttribute(
        "data-rate"
      )
    );
    let rate = this.rateTarget.value;

    if (!rate) {
      rate = defaultRate;
      this.rateTarget.value = rate;
    }
    rate = parseFloat(rate);

    const defaultQuantity = 1;
    let quantity = this.quantityTarget.value;

    if (!quantity) {
      quantity = defaultQuantity;
      this.quantityTarget.value = quantity;
    }

    const startTime = moment(this.startTimeTarget.value);
    const finishTime = moment(this.finishTimeTarget.value);

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    if (startTime && finishTime) {
      if (finishTime <= startTime) {
        console.log("Finish time before start time");
        this.hoursWorkedErrorTarget.classList.remove("hidden");
      } else if (!rate) {
        console.log("Rate somehow undefined or NaN");
      } else {
        console.log("Finish time after start time");
        this.hoursWorkedErrorTarget.classList.add("hidden");
        const hoursWorked = Math.abs(startTime.diff(finishTime, "minutes") / 60);
        const cost = hoursWorked * rate * quantity;

        this.hoursWorkedTarget.innerHTML = `${quantity} staff, ${hoursWorked} hours @ ${formatter.format(rate)} : ${formatter.format(cost)}`;
      }
    }
  }
}
