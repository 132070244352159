import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="address-select"
export default class extends Controller {
  change(event) {
    const addressId = event.target.value

    get(`/${addressId}/address_selected`, {
      responseKind: 'turbo-stream'
    })
  }
}