import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "beverages", "food", "menuCategorySelect" ]
  
  menuCategorySelected() {
    const menuCategory = this.menuCategorySelectTarget.value

    if (menuCategory == "food") {
      this.showFoodSection()
    } else {
      this.showBeverageSection()
    }
  }

  showFoodSection() {
    this.foodTarget.classList.remove("hidden")
    this.beveragesTarget.classList.add("hidden")
  }

  showBeverageSection() {
    this.foodTarget.classList.add("hidden")
    this.beveragesTarget.classList.remove("hidden")
  }
}
