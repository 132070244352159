import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  open(event) {
    event.preventDefault();
    const url = event.target.href;
    const turboFrame = event.target.dataset.turboFrame;
    this.sourcePage = event.target.dataset.sourcePage; 

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const frame = doc.querySelector(`turbo-frame[id="${turboFrame}"]`);
        this.modalTarget.innerHTML = "";
        this.modalTarget.appendChild(frame);
      });

    this.modalTarget.classList.remove("hidden");
  }
}
