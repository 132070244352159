import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="online-order-form-item-category-check-box"
export default class extends Controller {
  static targets = ['checkBox']
  static values = { itemCategoryId: Number, onlineOrderFormId: Number }

  toggle() {
    const formCategorySection = document.getElementById(`online_order_form_items_content_${this.itemCategoryIdValue}`)
    if (formCategorySection) {
      formCategorySection.classList.toggle("hidden")
    } else {
      get(`/online_order_forms/${this.onlineOrderFormIdValue}/items?item_category_id=${this.itemCategoryIdValue}`, {
        responseKind: 'turbo-stream'
      })
    }
  }
}
