import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js"

// Connects to data-controller="online-order-totals"
export default class extends Controller {
  static values = {
    catererName: String,
  }

  calculate() {
    const form = this.element
    const formData = new FormData(form)
    const url = `/online/${this.catererNameValue}/orders/totals`

    put(url, { body: formData, responseKind: "turbo-stream" })
  }
}
