// app/javascript/controllers/confirm_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit(event) {
    event.preventDefault();
    const message = this.data.get("message") || "Are you sure you want to duplicate this order?";
    const confirmed = confirm(message);

    if (confirmed) {
      this.element.submit();
    }
  }
}
