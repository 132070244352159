// app/javascript/controllers/lead_time_controller.js
import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="lead-time"
export default class extends Controller {
  static targets = ["leadTimeHours"]
  static values = { dayOfWeek: String, onlineOrderFormId: String, earliestDeliveryTimeDocumentId: String }

  connect() {
    window.addEventListener("earliest-delivery-time:changed", this.calculate.bind(this))
  }

  disconnect() {
    window.removeEventListener("earliest-delivery-time:changed", this.calculate.bind(this))
  }

  calculate() {
    const form = this.element.closest('form')
    const earliestDeliveryTimeElement = form.querySelector(`#${this.earliestDeliveryTimeDocumentIdValue}`)
    if (!earliestDeliveryTimeElement) {
      console.error('Could not find earliestDeliveryTimeElement')
      return
    }

    const earliestDeliveryTime = earliestDeliveryTimeElement.value
    const leadTimeHours = this.leadTimeHoursTarget.value

    get(`/online_order_forms/${this.onlineOrderFormIdValue}/online_order_form_settings/latest_delivery_time?day_of_week=${this.dayOfWeekValue}&earliest_delivery_time=${earliestDeliveryTime}&lead_time_hours=${leadTimeHours}`, {
      responseKind: 'turbo-stream'
    })
  }
}
