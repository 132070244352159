import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
   static targets = ["selectAll", "checkbox"]

  connect() {
    this.selectAllTargets.forEach(selectAll => {
      selectAll.addEventListener('change', (event) => this.toggleColumn(event))
    })
  }

  disconnect() {
    this.selectAllTargets.forEach(selectAll => {
      selectAll.removeEventListener('change', (event) => this.toggleColumn(event))
    })
  }

  toggleColumn(event) {
    const selectAll = event.target
    const columnIndex = Array.from(this.selectAllTargets).indexOf(selectAll)
    const checkboxes = this.checkboxTargets.filter((checkbox, index) => {
      return index % this.selectAllTargets.length === columnIndex
    })

    checkboxes.forEach(checkbox => {
      checkbox.checked = selectAll.checked
    })
  }
}
