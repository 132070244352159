import { Controller } from "@hotwired/stimulus"
let debounce = require("lodash/debounce")

// Connects to data-controller="debounce"
export default class extends Controller {
  static targets = ["form"]

  initialize() {
    this.submit = debounce(this.submit, 1000).bind(this)
  }

  submit() {
    console.log("debounce submit")
    this.formTarget.requestSubmit()
  }
}