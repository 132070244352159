import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";
import Sortable from "sortablejs";

/*
 * Usage
 * =====
 *
 * add data-controller="sortable" to common ancestor
 *
 * 1. Add data-sortable-url-value="URL to update position" to the
 * controller container
 *
 * 2. Add data-sortable-handle to the drag handle:
 *
 * 3. Add data-sortable-id to the item
 *
 */
export default class extends Controller {
  static values = { url: String, className: String, showIndex: Boolean };

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      handle: "[data-sortable-handle]",
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  onEnd(event) {
    const { newIndex, item } = event
    const id = item.dataset["sortableId"]
    const className = this.classNameValue
    const url = this.urlValue.replace("idValue", id)

    patch(url, {
      body: JSON.stringify({ [className]: { position: newIndex } }),
      responseKind: 'turbo-stream'
    })

    const showIndex = this.showIndexValue
    if (showIndex) {
      let position = 0
      this.sortable.toArray().forEach(element => {
        document.getElementById(`${className}-index-${element}`).textContent = position
        position++
      })
    }
  }
}