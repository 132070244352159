import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-type-select"
export default class extends Controller {
  static targets = ['select', 'deliveryTimes', 'eventStartTime', 'collectionTime', 'chefAndDriverInstructions']

  connect() {
    this.toggleDeliveryFields()
    this.toggleVenueOnlyFields()
  }
  select() {
    this.toggleDeliveryFields()
    this.toggleVenueOnlyFields()
  }

  toggleDeliveryFields() {
    this.selectTarget.selectedOptions[0].dataset.hasDelivery === 'true' ? this.showDeliveryTimes() : this.hideDeliveryTimes()
    this.selectTarget.selectedOptions[0].dataset.hasStartTime === 'true' ? this.showStartTime() : this.hideStartTime()
    this.selectTarget.selectedOptions[0].dataset.hasCollection === 'true' ? this.showCollectionTime() : this.hideCollectionTime()
  }

  toggleVenueOnlyFields() {
    console.log("toggle venue only fields")
    console.log(this.selectTarget.selectedOptions[0].dataset)

    this.selectTarget.selectedOptions[0].dataset.isVenueOnly === 'true' ? this.hideChefAndDriverInstructions() : this.showChefAndDriverInstructions()
  }

  showDeliveryTimes() { this.deliveryTimesTarget.classList.remove('hidden') }
  hideDeliveryTimes() { this.deliveryTimesTarget.classList.add('hidden') }

  showStartTime() { this.eventStartTimeTarget.classList.remove('hidden') }
  hideStartTime() { this.eventStartTimeTarget.classList.add('hidden') }

  showCollectionTime() { this.collectionTimeTarget.classList.remove('hidden') }
  hideCollectionTime() { this.collectionTimeTarget.classList.add('hidden') }

  showChefAndDriverInstructions() { this.chefAndDriverInstructionsTarget.classList.remove('hidden') }
  hideChefAndDriverInstructions() { this.chefAndDriverInstructionsTarget.classList.add('hidden') }
}
