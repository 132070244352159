import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-deliveries"
export default class extends Controller {
  static targets = ["template", "container"]
  connect() {}

  add(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }
}
