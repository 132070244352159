import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {
    console.log("Invoiced controller connected!")
  }

  update() {
    let checkbox = this.checkboxTarget;
    let reservationId = checkbox.dataset.id;
    let isChecked = checkbox.checked;
    
    Rails.ajax({
      url: `/reservations/${reservationId}`,
      type: 'PATCH',
      contentType: 'application/json',
      data: JSON.stringify({ 
        reservation: { 
          invoiced: isChecked
        }
      }),
      success: function(data) {
        console.log('Update successful!')
      },
      error: function(data) {
        console.error('There was an error updating the reservation', data);
      }
    })
  }
}
