import { Controller } from "@hotwired/stimulus"
import moment from 'moment'

// Connects to data-controller="delivery-field"
export default class extends Controller {
  static targets = ["container", "tooEarly", "tooLate"]
  static values = { callRefresh: Boolean, orderFormId: String, earliestAcceptable: String, latestAcceptable: String }

  parseTimeString(timeString) {
    const normalizedString = timeString.trim();
    const parsedTime = moment(normalizedString, ["h:mm A", "HH:mm"], true);

    if (!parsedTime.isValid()) {
      throw new Error('Invalid time format');
    }

    return parsedTime.toDate();
  }

  changed(event) {
    const time = this.parseTimeString(event.target.value)

    if (this.earliestAcceptableValue) {
      const earliest = this.parseTimeString(this.earliestAcceptableValue)
      if (earliest > time) {
        this.tooEarlyTarget.classList.remove("hidden")
      } else {
        this.tooEarlyTarget.classList.add("hidden")
      }
    }

    if (this.latestAcceptableValue) {
      const latest = this.parseTimeString(this.latestAcceptableValue)
      if (time > latest) {
        this.tooLateTarget.classList.remove("hidden")
      } else {
        this.tooLateTarget.classList.add("hidden")
      }
    }
  }

  remove(event) {
    event.preventDefault()
    const container = this.containerTarget
    container.remove()

    // refresh deliveries after a delivery is removed, when appropriate.
    if (this.callRefreshValue) {
      console.log("Attempting to refresh deliveries after removal!!")
      // Rare occasion where we need to call another stimulus controller. Don't make this a pattern!
      const orderDeliveriesController = this.application.getControllerForElementAndIdentifier(
        document.getElementById(this.orderFormIdValue),
        "online-order-deliveries"
      )

      if (orderDeliveriesController) {
        orderDeliveriesController.deliveryChanged()
      }
    }
  }
}