import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    id: Number,
    successMessage: String,
    errorMessage: String
  }

  connect() {
    this.clipboard = new ClipboardJS(this.element);
    this.clipboard.on("success", (e) => this.tooltip(this.successMessageValue))
    this.clipboard.on("error",   (e) => this.tooltip(this.errorMessageValue))

    this.tippyInstance = tippy(this.element, {
      trigger: 'manual',
      theme: 'material',
      placement: 'top',
      duration: [200, 150],
      animation: 'scale',
      arrow: true,
      hideOnClick: true,
      maxWidth: 'none',
      zIndex: 9999,
      appendTo: document.body,
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              padding: 5
            }
          }
        ]
      }
    });

  }

  disconnect() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
    }
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  tooltip(message) {
    this.tippyInstance.setContent(message);
    this.tippyInstance.show();

    // Hide the tooltip after 2 seconds
    setTimeout(() => {
      this.tippyInstance.hide();
    }, 2000);
  }
}
