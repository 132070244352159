import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="menu-category-check-box"
export default class extends Controller {
  static targets = ['checkBox']
  static values = { id: Number, menuId: Number }

  toggle() {
    const menuCategorySection = document.getElementById(`menu_items_content${this.idValue}`)
    if (menuCategorySection) {
      menuCategorySection.classList.toggle("hidden")
    } else {
      get(`/${this.idValue}/menu_item_category_selected?menu_id=${this.menuIdValue}`, {
        responseKind: 'turbo-stream'
      })
    }
  }
}
