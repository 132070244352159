// app/javascript/controllers/orders_count_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dateField', 'ordersCount'];

  connect() {
    this.dateFieldTarget.addEventListener('change', () => this.fetchOrdersCount());
  }

  async fetchOrdersCount() {
    const date = this.dateFieldTarget.value;
    try {
      const response = await fetch(`/orders/orders_count_by_date?date=${date}`, {
        headers: { 'Accept': 'application/json' }
      });
      if (response.ok) {
        const data = await response.json();
        const pluralize = (count, word) => count === 1 ? word : `${word}s`;
        this.ordersCountTarget.innerHTML = `On this date there are ${data.accepted_count} ${pluralize(data.accepted_count, 'Accepted order')}, including ${data.accepted_staffed_count} ${pluralize(data.accepted_staffed_count, 'staffed event')}.<br>There are ${data.pending_count} ${pluralize(data.pending_count, 'Pending order')}, including ${data.pending_staffed_count} ${pluralize(data.pending_staffed_count, 'staffed event')}.`;
      } else {
        this.ordersCountTarget.innerHTML = "";
      }
    } catch (error) {
      this.ordersCountTarget.innerHTML = "";
    }
  }
}
