// app/javascript/controllers/docket_badge_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  loadDocket(event) {
    const url = event.target.getAttribute("data-url")
    const frame = document.getElementById("docket-frame");
    frame.setAttribute("src", url);

    document.getElementById("modal").classList.remove("hidden");
  }

  closeModal() {
    document.getElementById("modal").classList.add("hidden")
  }
}
