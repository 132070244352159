// app/javascript/controllers/time_calculator_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Time calculator controller connected");
  }

  handleResult(event) {
    console.log("Handle result called", event.detail);
    const { success, fetchResponse } = event.detail;

    if (success) {
      fetchResponse.text().then((data) => {
        console.log("Result received", data);
        // Process the data as needed
      }).catch((error) => {
        console.error("Error processing fetch response", error);
      });
    } else {
      console.error("Form submission failed", fetchResponse);
    }
  }
}
