import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["calendar"]

  refresh(event) {
    event.preventDefault()
    const element = event.currentTarget;
    const date = element.dataset.date
    const url = `/orders/calendar?month=${date}`

    fetch(url, { headers: { 'Accept': 'text/javascript' }})
      .then(response => response.text())
      .then(html => {
        this.calendarTarget.outerHTML = html;
      })
  }
}
