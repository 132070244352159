import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="order-item"
export default class extends Controller {
  static targets = [
    "quantity",
    "quantityReturned",
    "price",
    "amount",
    "deliveryDropdown",
    "addDeliveryButton",
    "container",
    "template",
    "name",
    "editName"
  ]
  static values = {
    itemId: Number,
    divId: String,
    date: String,
    orderId: String,
  }

  showEditName() {
    console.log("Edit name")

    this.nameTarget.classList.toggle("hidden")
    this.editNameTarget.classList.toggle("hidden")
  }

  updateAmount() {
    post(`/orders/amount`, {
      body: {
        price: this.priceTarget.value,
        quantity: this.quantityTarget.value - this.quantityReturnedTarget.value,
        item_id: this.itemIdValue,
        div_id: this.divIdValue,
        date: this.dateValue,
        order_id: this.orderIdValue
      },
      responseKind: 'turbo-stream'
    })
  }

  updatePrice() {
    const priceValue = parseFloat(this.priceTarget.value)
    if (!isNaN(priceValue)) {
      this.priceTarget.value = priceValue.toFixed(2)
    }
  }
  
  
  

  addDelivery() {
    console.log("Add delivery")

    const randomIdentifier = Date.now()
    const content = this.templateTarget.innerHTML.replace(/NEW_ID/g, randomIdentifier)
    this.containerTarget.insertAdjacentHTML('afterend', content)
  }

  removeDelivery() {
    console.log("Remove delivery")

    this.containerTarget.remove()
  }
}
