import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="discount"
export default class extends Controller {
  static targets = [
    "noneRadio",
    "percentageRadio",
    "absoluteRadio",
    "percentageInput",
    "absoluteInput"
  ]

  connect() {
    if (this.percentageRadioTarget.checked == false && this.absoluteRadioTarget.checked == false) {
      this.noneRadioTarget.checked = true
    }
  }

  radioChange(event) {
    if (this.percentageRadioTarget.checked) {
      this.absoluteInputTarget.value = "0.00"
    } else if (this.absoluteRadioTarget.checked) {
      this.percentageInputTarget.value = "0.00"
    } else {
      this.absoluteInputTarget.value = "0.00"
      this.percentageInputTarget.value = "0.00"
    }
  }
}