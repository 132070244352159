import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  navigateToDocket(event) {
    const selectedOption = event.target.value;
    const turboFrame = document.getElementById("docket-frame");

    if (selectedOption) {
      turboFrame.src = selectedOption;
    }
  }
}
