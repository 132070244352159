import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="discount"
export default class extends Controller {
  static targets = [
    "slider",
    "input",
    "discountLabel",
    "surchargeLabel",
    "percentageRadio",
    "percentageInput",
    "absoluteRadio",
    "absoluteInput",
    "noDiscountRadio",
    "discountRadio",
    "surchargeRadio",
    "inputsContainer"
  ]

  connect() {
    const hiddenField = this.element.querySelector('.hidden-price-modifier');
    const inputValue = parseFloat(hiddenField.value) || 100;
    this.sliderTarget.value = inputValue;
    this.inputTarget.value = `${this.sliderToInputValue(inputValue)}%`;

    this.updateLabels(this.sliderToInputValue(inputValue));

    if (this.noDiscountRadioTarget.checked) {
      this.hideDiscountInputs()
    } else {
      this.showDiscountInputs()
    }
  }

  hideDiscountInputs() {
    this.inputsContainerTarget.classList.add("hidden");
    this.percentageRadio.checked = false
    this.absoluteRadio.checked = false
  }

  showDiscountInputs() {
    this.inputsContainerTarget.classList.remove("hidden");
  }

  sliderChange(event) {
    const adjustedValue = this.sliderToInputValue(event.target.value);
    this.inputTarget.value = `${adjustedValue}%`;
    this.updateLabels(adjustedValue);

    const hiddenField = this.element.querySelector('.hidden-price-modifier');
    hiddenField.value = event.target.value;
  }

  discountTypeRadioChange(event) {
    if (this.discountRadioTarget.checked || this.surchargeRadioTarget.checked) {
      this.showDiscountInputs()
    } else {
      this.hideDiscountInputs()
    }
  }

  radioChange(event) {
    const percentageSelected = this.percentageRadioTarget.checked
    if (percentageSelected) {
      this.absoluteInputTarget.value = "0.00"
    } else {
      this.percentageInputTarget.value = "0.00"
    }
  }

  inputChange(event) {
    const displayValue = event.target.value.replace(/\%/, "");
    const originalValue = this.inputToSliderValue(displayValue);
    this.sliderTarget.value = originalValue;
    this.updateLabels(displayValue);
    event.target.value = `${displayValue}%`;

    const hiddenField = this.element.querySelector('.hidden-price-modifier');
    hiddenField.value = originalValue;
  }

  updateLabels(value) {
    const isDiscount = parseFloat(value) < 0;
    const isSurcharge = parseFloat(value) > 0;

    if (isDiscount) {
      this.discountLabelTarget.classList.remove("hidden");
    } else {
      this.discountLabelTarget.classList.add("hidden");
    }

    if (isSurcharge) {
      this.surchargeLabelTarget.classList.remove("hidden");
    } else {
      this.surchargeLabelTarget.classList.add("hidden");
    }
  }

  sliderToInputValue(value) {
    return parseFloat(value) - 100;
  }

  inputToSliderValue(value) {
    return parseFloat(value) + 100;
  }
}
