import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "allVenues",
    "specificVenues",
    "venueSelectSection",
  ]

  connect() {
    this.radioToggled()
  }

  radioToggled = () => {
    if (this.allVenuesTarget.checked) {
      this.venueSelectSectionTarget.classList.add("hidden")
    } else {
      this.venueSelectSectionTarget.classList.remove("hidden")
    }
  }
}
