import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["customMenu"];

  connect() {
    console.log("CustomMobile controller connected");
  }

  toggle(event) {
    event.preventDefault();
    this.customMenuTarget.classList.toggle("hidden");
    this.toggleScrollLock();
    this.toggleAriaExpanded(event.currentTarget);
  }

  open() {
    this.customMenuTarget.classList.remove("hidden");
    this.toggleScrollLock(true);
  }

  close() {
    this.customMenuTarget.classList.add("hidden");
    this.toggleScrollLock(false);
  }

  toggleScrollLock(enable = null) {
    if (enable === true || (enable === null && document.body.classList.contains("overflow-hidden"))) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }

  toggleAriaExpanded(button) {
    const isExpanded = button.getAttribute("aria-expanded") === "true";
    button.setAttribute("aria-expanded", !isExpanded);
  }
}
