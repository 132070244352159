import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer_select"
export default class extends Controller {
  close() {
    const elements = document.getElementsByClassName("slideover-control-div")
    for (let element of elements) {
      element.classList.add("fade-out")
      setTimeout(() => {
        element.classList.add("hidden")
        element.classList.remove("fade-out")
      }, 300) // Adjust this time to match your CSS transition duration
    }
  }
}
