import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: Number
  }

  connect() {
    // Run AJAX on button click
    this.element.addEventListener('click', (e) => this.saveAndGenerate(e));
  }

  saveAndGenerate(e) {
    e.preventDefault();

    $.ajax({
      url: '/reservations/' + this.idValue + '/save_and_generate',
      method: 'PATCH',
      dataType: 'json',
      success: (response) => {
        if (response.status === 'success') {
          alert('Invoiced status updated');
          $('#invoiced_status').text('True');
        } else {
          alert('Failed to update');
        }
      },
      error: (response) => {
        alert('Failed to update');
      }
    });
  }
}
