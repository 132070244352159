import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js"

// Connects to data-controller="online-customer-state"
export default class extends Controller {
  static values = {
    orderUuid: String,
    catererName: String,
  }

  autosave() {
    const form = this.element
    const formData = new FormData(form)
    const url = `/online/${this.catererNameValue}/orders/${this.orderUuidValue}/customer_state`

    put(url, { body: formData })
  }
}
