import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "filePreview", "fileRemove"];
  static values = { validFileTypes: Array };

  connect() {
    this.dropzone = this.element;
    this.dropzone.addEventListener("click", this.handleClick.bind(this));
    this.dropzone.addEventListener("dragover", this.handleDragOver.bind(this));
    this.dropzone.addEventListener("dragleave", this.handleDragLeave.bind(this));
    this.dropzone.addEventListener("drop", this.handleDrop.bind(this));
    this.fileInputTarget.addEventListener("change", this.handleFileInputChange.bind(this));
  }

  handleClick() {
    this.fileInputTarget.click();
  }

  handleDragOver(event) {
    event.preventDefault();
    this.dropzone.classList.add("hover");
  }

  handleDragLeave() {
    this.dropzone.classList.remove("hover");
  }

  handleDrop(event) {
    event.preventDefault();
    this.dropzone.classList.remove("hover");

    const files = event.dataTransfer.files;
    if (files.length) {
      this.handleFile(files[0]);
    }
  }

  handleFileInputChange(event) {
    const files = event.target.files;
    if (files.length) {
      this.handleFile(files[0]);
    }
  }

  removeFile(event) {
    this.filePreviewTarget.innerHTML = ""; // Clear preview
    this.dropzone.querySelector(".dropzone-msg").style.display = "block"; // Show message

    // Clear the file input
    const dataTransfer = new DataTransfer();
    this.fileInputTarget.files = dataTransfer.files;

    if (this.hasFileRemoveTarget) {
      // Update the file remove target
      this.fileRemoveTarget.value = "true"
    }

    event.stopPropagation();
  }

  handleFile(file) {
    const maxFileSize = 8 * 1024 * 1024; // 8 MB

    if (this.validFileTypesValue.length > 0 && !this.validFileTypesValue.includes(file.type)) {
      alert(`Only files of type: ${this.validFileTypesValue.join(", ")} are allowed.`);
      return;
    }

    if (file.size > maxFileSize) {
      alert("File size exceeds 8 MB.");
      return;
    }

    this.filePreviewTarget.innerHTML = ""; // Clear previous preview
    this.dropzone.querySelector(".dropzone-msg").style.display = "none"; // Hide message

    const reader = new FileReader();
    reader.onload = (event) => {
      const previewElement = document.createElement(file.type.startsWith("image/") ? "img" : "span");
      previewElement.src = event.target.result;
      previewElement.textContent = file.name;
      this.filePreviewTarget.appendChild(previewElement);
      this.filePreviewTarget.style.opacity = 1;
    };
    reader.readAsDataURL(file);

    // Attach the file to the hidden file input for form submission
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    this.fileInputTarget.files = dataTransfer.files;

    if (this.hasFileRemoveTarget) {
      // make sure file removal is not set to true
      this.fileRemoveTarget.value = "false"
    }
  }
}
