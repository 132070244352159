// app/frontend/controllers/chartkick_controller.js

import { Controller } from "@hotwired/stimulus";
import Chartkick from "chartkick";
import { Chart, BarController, CategoryScale, LinearScale, BarElement } from 'chart.js';

// Register the controllers, scales, and elements you want to use
Chart.register(BarController, CategoryScale, LinearScale, BarElement);




Chartkick.use(Chart);

export default class extends Controller {
  connect() {
    this.loadData();
  }

  async loadData() {
    // Fetch your data here. For demonstration:
    const data = await fetch("/path_to_endpoint_returning_data").then(response => response.json());

    new Chartkick.ColumnChart(this.element, data, { stacked: true });
  }
}
