import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-documents"
export default class extends Controller {
  static targets = ["text", "input", "fileInput"]

  connect() {
    this.selectedDocumentId = this.inputTarget.value
    console.log(this.selectedDocumentId)
  }

  handleFileChange(event) {
    if (event.target.value !== undefined) {
      this.fileAttached()
      this.textTarget.value = ""
    }
  }

  handleChange(event) {
    const selectedValue = event.target.value
    if (this.selectedDocumentId == selectedValue) {
      return
    }

    const selectedOption = Array.from(event.target.options).find(option => option.value === selectedValue);
    if (selectedOption && selectedOption.value != "") {
      this.selectedDocumentId = selectedOption.value
      this.textTarget.value = selectedOption.text
    }
  }

  fileAttached() {
    // preferred method.
    // this.dispatch("disableSelect")

    const selectController = this.application.getControllerForElementAndIdentifier(this.inputTarget, 'select')
    selectController.disable()
  }
}
