import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="customer_select"
export default class extends Controller {
  static values = { formColSpan: { type: Number, default: 1 } }

  customerChange(event) {
    const customerId = event.target.value
    const venueId = document.getElementsByName("order[venue_id]")[0]?.value

    get(`/customer_selected?customer_id=${customerId}&venue_id=${venueId}&changed=customer&form_col_span=${this.formColSpanValue}`, {
      responseKind: 'turbo-stream'
    })
  }

  venueChange(event) {
    const venueId = event.target.value
    const customerId = document.getElementsByName("order[customer_id]")[0]?.value

    get(`/customer_selected?customer_id=${customerId}&venue_id=${venueId}&changed="venue"&form_col_span=${this.formColSpanValue}`, {
      responseKind: 'turbo-stream'
    })
  }
}
