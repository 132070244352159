// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"
import ColorPicker from "stimulus-color-picker"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown);
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
application.register('color-picker', ColorPicker)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import SortableController from "./sortable_controller"
application.register("sortable", SortableController)

import CustomDropdown from "./custom_dropdown_controller"; // Ensure correct path
application.register('custom-dropdown', CustomDropdown); // This should replace the default Dropdown
