import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    if (this.element.tagName.toLowerCase() === "select") {
      this.select = new SlimSelect({ select: this.element });
    }
  }

  disconnect() {
    if (this.select && typeof this.select.destroy === "function") {
      this.select.destroy();
    }
  }

  disable() {
    this.select.disable()
  }

  enable() {
    this.select.enable()
  }

  navigateToDocket(event) {
    const selectedOption = event.target.value;
    const orderId = this.element.dataset.orderId;
    const turboFrame = document.getElementById("docket-frame");

    if (selectedOption) {
      turboFrame.src = selectedOption;
    }
  }
}
