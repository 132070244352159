// app/javascript/controllers/earliest_delivery_time_controller.js
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="earliest-delivery-time"
export default class extends Controller {
  static targets = ["earliestDeliveryTime"]

  triggerEvent() {
    const inputValue = this.earliestDeliveryTimeTarget.value
    const customEvent = new CustomEvent("earliest-delivery-time:changed", {
      detail: { value: inputValue }
    })

    window.dispatchEvent(customEvent)
  }
}