import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="item-category-check-box"
export default class extends Controller {
  static targets = ['checkBox']
  static values = { id: Number, orderId: Number, orderSessionId: String }

  connect() {
    const itemCategorySection = document.getElementById(`order_items_content_${this.idValue}`)
    if (itemCategorySection) {
      this.checkBoxTarget.checked = true
    } else {
      this.checkBoxTarget.checked = false
    }
  }

  toggle() {
    const itemCategorySection = document.getElementById(`order_items_content_${this.idValue}`)
    if (itemCategorySection) {
      itemCategorySection.classList.toggle("hidden")
    } else {
      get(`/${this.idValue}/order_item_category_selected?id=${this.orderIdValue}&order_session_id=${this.orderSessionIdValue}`, {
        responseKind: 'turbo-stream'
      })
    }
  }
}
