import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["customMenu"];

  connect() {
    console.log("CustomDropdown controller connected");
    console.log("Element:", this.element);
    console.log("Custom Menu target found?", this.hasCustomMenuTarget);
    if (this.hasCustomMenuTarget) {
      console.log("Custom Menu target:", this.customMenuTarget);
    } else {
      console.error("Custom Menu target not found!");
    }
  }

  toggle(event) {
    console.log("Toggle method called");

    if (!this.hasCustomMenuTarget) {
      console.error("Cannot toggle because custom menu target is missing");
      return;
    }

    if (this.customMenuTarget.classList.contains('hidden') || this.customMenuTarget.classList.contains('opacity-0')) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    console.log("Open method called");

    if (!this.hasCustomMenuTarget) {
      console.error("Cannot open because custom menu target is missing");
      return;
    }

    this.customMenuTarget.classList.remove('hidden');
    this.customMenuTarget.classList.add('opacity-0', '-translate-y-1');

    // Force reflow to ensure the transition works
    this.customMenuTarget.offsetHeight;

    this.customMenuTarget.classList.remove('opacity-0', '-translate-y-1');
    this.customMenuTarget.classList.add('transition', 'ease-out ', 'duration-200', 'opacity-100', 'translate-y-0');
  }

  close() {
    console.log("Close method called");

    if (!this.hasCustomMenuTarget) {
      console.error("Cannot close because custom menu target is missing");
      return;
    }

    this.customMenuTarget.classList.add('transition', 'ease-in', 'duration-200', 'opacity-0', 'translate-y-1');
    this.customMenuTarget.classList.remove('opacity-100', 'translate-y-1');

    setTimeout(() => {
      this.customMenuTarget.classList.add('hidden');
      console.log("Dropdown menu hidden");
    }, 150);
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }
}
