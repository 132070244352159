/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

require("@rails/activestorage").start()
import "@rails/actiontext"

// Tailwind-elements dynamic components
import 'tw-elements'

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Helpers
import "./helpers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// Start Rails UJS
Rails.start()
import "trix"
import "@rails/actiontext"

import "chartkick/chart.js"


