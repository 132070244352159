import Flatpickr from 'stimulus-flatpickr'

// Connects to data-controller="delivery-flatpickr"
export default class extends Flatpickr {
  initialize() {
    this.config = {
      noCalendar: true,
      dateFormat: "h:i K",
      enableTime: true,
      time_24hr: false,
    }
  }

  close(selectedDates, dateStr, instance) {
    this.dispatch("deliveryChanged", {detail: {}})
  }
}

