import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    url: String, // Base URL without the ID and move action
    className: String,
    showIndex: Boolean
  };

  connect() {
    this.sortable = Sortable.create(this.element.querySelector('tbody'), {
      handle: "[data-sortable-handle]",
      animation: 150,
      onEnd: this.onEnd.bind(this),
    });
    console.log("Sortable table connected!");
    console.log("Connected URL Value:", this.urlValue);
  }

  disconnect() {
    this.sortable.destroy();
  }

  onEnd(event) {
    const { newIndex, item } = event;
    const id = item.dataset["sortableId"];
    const className = this.classNameValue;
    const url = this.urlValue.replace("idValue", id)

    const payload = JSON.stringify({ [className]: { position: newIndex } })
    console.log("Request URL:", url);
    console.log("Payload:", payload);

    // Send a PATCH request with the correctly structured payload
    patch(url, {
      body: payload,
      headers: {
        'Content-Type': 'application/json' // Ensuring header is set to application/json
      }
    }).then(response => {
      if (!response.ok) {
        console.error("HTTP error", response.status);
      } else {
        console.log("Move successful!");
      }
    }).catch(error => console.error("Network error:", error));

    const showIndex = this.showIndexValue
    if (showIndex) {
      let position = 0
      this.sortable.toArray().forEach(element => {
        document.getElementById(`${className}-index-${element}`).textContent = position
        position++
      })
    }
}

}