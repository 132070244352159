import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer-type-select"
export default class extends Controller {
  selected(event) {
    console.log(event)
    const customerType = event.target.value
    const companyNameSection = 'company-name-section'
    console.log(customerType)

    if (customerType === "individual") {
      document.getElementById(companyNameSection).style.display = 'none';
    } else {
      document.getElementById(companyNameSection).style.display = 'block';
    }
  }
}