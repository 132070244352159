import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="commentary-select"
export default class extends Controller {
  static targets = ["content", "select"]

  change(event) {
    const content = this.selectTarget.options[event.target.selectedIndex]?.getAttribute("data-content")
    if (!content) return

    this.contentTarget.value = content
  }
}